export const MenuLinks = {
  // Resources tab
  login: "/login.aspx",
  //'login': '/MasaratLogin.aspx',

  FMSDashboard: "/WebPages/management/Dashboard.aspx",
  Transporters: "/WebPages/Transporters/NewList.aspx",
  Staff: "/WebPages/Staff/List.aspx",
  StructureAndDistribution: "/WebPages/StructureAndDistribution/Index.aspx",

  // Maintenance tab
  RequestForRepair: "/WebPages/RequestForRepair/List.aspx",
  AccidentRequestForRepair: "/WebPages/RequestForRepair/AccidentList.aspx",
  PMRequestForRepair: "/WebPages/RequestForRepair/PMList.aspx",
  CMRequestForRepair: "/WebPages/RequestForRepair/CMList.aspx",
  WorkOrder: "/WebPages/MaintenanceWO/List.aspx",
  VendorWorkOrder: "/WebPages/MaintenanceWO/VendorList.aspx",
  PreventiveMaintenance: "/WebPages/MaintenancePM/List.aspx",
  lnk_MaintenanceDashboard: "/WebPages/MaintenanceDashboard/dashboard.aspx",

  //Operations tab
  Landmarks: "/WebPages/Landmarks/List.aspx",
  Routes: "/WebPages/Routes/List.aspx",
  WorkOrderList: "/WebPages/NewOperations/WorkOrdersList.aspx",
  WorkOrderCostList: "/WebPages/NewOperations/WorkOrderListWithCosts.aspx",

  // GPS Tab
  Map: "/WebPages/Maps.aspx",
  MapMonitor: "/WebPages/UpdateTransportersData.aspx",
  Geofences: "/WebPages/GeofenceList.aspx",
  GroupManagment: "/WebPages/GroupsManagement.aspx",

  // Rent Cars Tab
  CarRentDashBoard: "/WebPages/HomePage/Home.aspx",
  PricePlanList: "/WebPages/PricePlan/List.aspx",
  AssignPricePlanToVehicles:
    "/WebPages/PricePlan/AssignPricePlanToVehicles.aspx",
  PromotionsList: "/WebPages/Promotion/List.aspx",
  AdditionalServices: "/WebPages/AdditionalServices/AdditionalServices.aspx",
  Customers: "/WebPages/Customers/List.aspx",
  RentCar: "/WebPages/CarRent/CarRent.aspx",
  ReturnPage: "/WebPages/VehicleReturn/VehicleReturn.aspx",
  ContractsList: "/WebPages/ContractsList/Contracts.aspx",

  // Reports tab
  GeneralReports: "/WebPages/NewReports/General/Index.aspx",
  GPSReports: "/WebPages/Reports/GPSReports.aspx",
  MaintenanceReports: "/WebPages/NewReports/Maintenance/Index.aspx",
  OperationReport: "/WebPages/NewReports/Operation/Index.aspx",
  RentalReports: "/WebPages/Reportes/ReportsRental.aspx",

  // Schools
  LiveStream: "/Schools/livestream",
  ADASAlarms: "/Schools/adasalarms",
  DSMAlarms: "/Schools/dsmalarms",
  AlarmsDashboard: "/Schools/alarmsdashboard",
  AlarmLifeDetection: "/Schools/alarmlifedetection",
  PalmReader: "/Schools/palmreader",
  FaceRecognition: "/Schools/facerecognition",
  StudentList: "/Schools/studentlist",
  PlanList: "/Schools/planlist",
  TripList: "/Schools/triplist",
  Dashboard: "/Schools/dashboard",
  GPSDashboard: "/GPSReporting/report",
  ControlPanel: "/WebPages/Administration/ControlPanel.aspx",
  MyAccount: "/WebPages/Users/userprofile.aspx",
};
