import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

// rx operators
import { Observable, Subject } from 'rxjs';

// DataModel and enums
import { AlertType } from '../../enums/alertType.enum'
import { alert } from '../../datamodels/alert/alert';

@Injectable({ providedIn: 'root'})
export class alertService {
    //prop
    private alertSubject = new Subject<alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }
    getAlert(): Observable<any> {
        return this.alertSubject.asObservable();
    }
    success(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Error, message, keepAfterRouteChange);
    }

    info(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Info, message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Warning, message, keepAfterRouteChange);
    }

    alert(alertType: AlertType, alertMessage: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.alertSubject.next(<alert>{ message: alertMessage, type: alertType, timeout: 10000 })
    }

    clear() {
        // clear alerts
        this.alertSubject.next();
    }
}
